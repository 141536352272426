exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-course-overview-js": () => import("./../../../src/pages/course-overview.js" /* webpackChunkName: "component---src-pages-course-overview-js" */),
  "component---src-pages-golf-pros-js": () => import("./../../../src/pages/golf-pros.js" /* webpackChunkName: "component---src-pages-golf-pros-js" */),
  "component---src-pages-green-fees-js": () => import("./../../../src/pages/green-fees.js" /* webpackChunkName: "component---src-pages-green-fees-js" */),
  "component---src-pages-how-to-find-us-js": () => import("./../../../src/pages/how-to-find-us.js" /* webpackChunkName: "component---src-pages-how-to-find-us-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-society-enquiries-js": () => import("./../../../src/pages/society-enquiries.js" /* webpackChunkName: "component---src-pages-society-enquiries-js" */),
  "component---src-pages-thank-you-js": () => import("./../../../src/pages/thank-you.js" /* webpackChunkName: "component---src-pages-thank-you-js" */),
  "component---src-templates-google-ads-js": () => import("./../../../src/templates/google-ads.js" /* webpackChunkName: "component---src-templates-google-ads-js" */)
}

